import {
    Button,
    Form,
    Input, message,
    Modal,
    notification,
    Space,
    Table
} from 'antd';
import React, {useState, useEffect} from 'react';
import AppAxios from "../../utils/request";
import CommonPagination from "../../components/Common/Pagination";
import copy from 'copy-to-clipboard';

const OrganList = () => {
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);

    const [tableData, setTableData] = useState([]);
    const [configLabels, setConfigLabels] = useState({});


    const [addModalVisible, setAddModalVisible] = useState(false);
    const [formAdd] = Form.useForm();


    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => <span>{record.name}({record.id})</span>,
        },
        {
            title: 'AUTH',
            dataIndex: 'auth',
            key: 'auth',
            width: 300,
            render: (_, record) => (
                <div>
                    <span>AppId:{record.appId}</span>
                    <br/>
                    <span>AppKey:{record.appKey}</span>
                </div>
            ),
        },
        {
            title: '回调地址',
            dataIndex: 'callbackCdr',
            key: 'callbackCdr',
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            formAdd.setFieldsValue(record)
                            setAddModalVisible(true)
                        }}>编辑</Button>
                    <Button
                        type="default"
                        size="small"
                        onClick={() => {
                            copy("名称:"+record.name+"\nAppId:"+record.appId+"\nAppKey:"+record.appKey)
                            message.success('复制完成');
                        }}>复制</Button>
                </Space>
            ),
        },
    ];

    const getDateList = () => {
        AppAxios.get("/organs", {
            params: {
                page: current,
                pageSize: pageSize,
            }
        }).then(res => {
            setLoading(false);
            if ((res.code === 2000 || res.code === 0)) {
                setTableData(res.data.list)
                setTotal(res.data.page.total)
            }
        }).catch((e) => setLoading(false))
    }
    const getConfigLabels = () => {
        AppAxios.get("/organ/config/labels").then(res => {
            if ((res.code === 2000 || res.code === 0)) {
                setConfigLabels(res.data)
            }
        }).catch((e) => {

        })
    }

    function GetConfigLabelsFrom() {
        let list = [];
        for (const key in configLabels) {
            list.push(
                <Form.Item
                    label={configLabels[key]}
                    name={['configs', key]}
                >
                    <Input/>
                </Form.Item>
            );
        }
        return <div>{list}</div>;
    }


    useEffect(() => {
        if (loading) {
            getDateList()
            getConfigLabels()
        }
    }, [loading, current, pageSize]);


    return (
        <div>
            <h3>机构管理</h3>

            <div style={{
                lineHeight: 3,
                display: "flex",
                justifyContent: "space-between",
            }}>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => setAddModalVisible(true)}>
                        新增
                    </Button>
                </Space>
            </div>
            <Table
                bordered
                columns={columns}
                dataSource={tableData}
                rowKey={columns => columns.id}
                loading={loading}
                pagination={false}
                footer={() => (
                    <CommonPagination
                        onChange={(page) => {
                            setCurrent(page);
                            setLoading(true);
                        }}
                        onShowSizeChange={(current, size) => {
                            setCurrent(1);
                            setPageSize(size);
                            setLoading(true);
                        }}
                        current={current}
                        total={total}
                        size={pageSize}
                    />
                )}
            />


            <Modal title="新增机构" visible={addModalVisible}
                   onOk={() => {
                       formAdd.validateFields()
                           .then(values => {
                               if (values.id) {
                                   //编辑
                                   AppAxios.put("/organ", values).then(res => {
                                       if ((res.code === 2000 || res.code === 0)) {
                                           formAdd.resetFields();
                                           notification["success"]({
                                               message: "操作成功",
                                               description: "操作成功",
                                           });
                                           setAddModalVisible(false);
                                           getDateList();
                                       }
                                   }).catch((e) => {

                                   })
                               } else {
                                   //新增
                                   AppAxios.post("/organ", values).then(res => {
                                       if ((res.code === 2000 || res.code === 0)) {
                                           formAdd.resetFields();
                                           notification["success"]({
                                               message: "操作成功",
                                               description: "操作成功",
                                           });
                                           setAddModalVisible(false);
                                           getDateList();
                                       }
                                   }).catch((e) => {

                                   })
                               }

                           })
                           .catch(info => {
                               console.log('Validate Failed:', info);
                           });
                   }}
                   onCancel={() => {
                       setAddModalVisible(false)
                   }}>
                <div>
                    <Form
                        form={formAdd}
                        name="basic"
                        labelCol={{span: 6,}}
                        initialValues={{remember: true}}
                        autoComplete="off"
                    >
                        <Form.Item label="id" name="id" hidden={true}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="机构名称"
                            name="name"
                            rules={[{required: true, message: '请输入电话号码'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="AppID"
                            name="appId"
                            rules={[{required: true, message: '请输入AppID'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="AppKey"
                            name="appKey"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="callbackCdr"
                            name="callbackCdr"
                        >
                            <Input/>
                        </Form.Item>

                        <GetConfigLabelsFrom></GetConfigLabelsFrom>

                    </Form>
                </div>
            </Modal>


        </div>
    );
}

export default OrganList
