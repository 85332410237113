import {
    Button, Input,
    message, notification,
    Space, Switch,
    Collapse,
    Col, Row, Table, Tag
} from 'antd';
import React, {useState, useEffect} from 'react';
import AppAxios from "../../utils/request";
import CommonSelect from "../../components/Common/Select";

const {TextArea} = Input;

const {Panel} = Collapse;
const ReplaceReallyPhone = () => {
    const [loadingAllot, setLoadingAllot] = useState(false);
    const [loadingPhones, setLoadingPhones] = useState(true);

    const [oldGatewayPhones, setOldGatewayPhones] = useState([]);
    const [oldGatewayId, setOldGatewayId] = useState("");

    const [oldPhoneData, setOldPhoneData] = useState([]);
    const [newPhoneData, setNewPhoneData] = useState([]);


    const getPhoneDateList = (gatewayId) => {
        AppAxios.get("/phones", {
            params: {
                page: 1,
                pageSize: 1000,
                gatewayId: gatewayId,
            }
        }).then(res => {
            setLoadingPhones(false);
            const phones = (res.code === 2000 || res.code === 0) && res.data.list.length > 0 ? res.data.list.map(item => item.number) : [];

            setOldGatewayPhones(phones);
        }).catch((e) => {
            setLoadingPhones(false)
        })
    }


    useEffect(() => {
        // getPhoneDateList()
    }, []);

    function countElement(arr, element) {
        let count = 0;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === element) {
                count++;
            }
        }
        return count;
    }

    function inPhones(number) {
        return oldGatewayPhones.indexOf(number) >= 0
    }

    function ShowTo() {
        const dataSource = [];
        const columns = [
            {
                title: '老号码',
                dataIndex: 'old',
                render: (item, _) => {
                    let tags = [];
                    if (countElement(oldPhoneData, item) > 1) {
                        tags.push(<Tag color="orange">重复</Tag>);
                    }
                    if (!inPhones(item)) {
                        tags.push(<Tag color="red">不存在</Tag>);
                    }
                    return (
                        <div>
                            <span>{item}</span>
                            {tags}
                        </div>
                    );
                }
            },
            {
                title: '新号码',
                dataIndex: 'new',
                render: (item, _) => {
                    let tags = [];
                    if (countElement(newPhoneData, item) > 1) {
                        tags.push(<Tag color="orange">重复</Tag>);
                    }
                    if (inPhones(item)) {
                        tags.push(<Tag color="red">存在</Tag>);
                    }
                    return (
                        <div>
                            <span>{item}</span>
                            {tags}
                        </div>
                    );
                }
            },
        ];
        if (oldPhoneData.length >= newPhoneData.length) {
            for (let i = 0; i < oldPhoneData.length; i++) {
                dataSource.push({
                    id: i + 1,
                    old: oldPhoneData[i],
                    new: newPhoneData[i] ?? newPhoneData[0]
                })
            }
        }

        return (
            <Table dataSource={dataSource}
                   columns={columns}
                   locale={{emptyText: "请输入老号码和新号码"}}
                   rowKey={columns => columns.id}
                   pagination={{
                       defaultPageSize: 500, showTotal: (to) => {
                           return "总数:" + to
                       }
                   }}
                   size="small"/>
        );
    }


    return (
        <div>
            <h2>替换号码</h2>

            <Button key="btn-allot" type="primary" loading={loadingAllot} onClick={() => {
                setLoadingAllot(true)
                let phones = []

                if (oldPhoneData.length >= newPhoneData.length) {
                    for (let i = 0; i < oldPhoneData.length; i++) {
                        phones.push({
                            oldPhone: oldPhoneData[i],
                            newPhone: newPhoneData[i] ?? newPhoneData[0]
                        })
                    }
                }

                if (phones.length <= 0) {
                    setLoadingAllot(false)
                    message.warning('请选择输入号码');
                    return;
                }

                if (oldGatewayId <= 0) {
                    setLoadingAllot(false)
                    message.warning('请选择旧号码所属网关');
                    return;
                }

                AppAxios.post("/phone/replace/really", {
                    oldGatewayId: oldGatewayId,
                    phones: phones,
                }).then(res => {
                    setLoadingAllot(false)
                    if ((res.code === 2000 || res.code === 0)) {
                        notification["success"]({
                            message: "操作成功",
                            description: "操作成功1",
                        });
                        setNewPhoneData([])
                        setOldPhoneData([])
                    } else {
                        message.warning(res.msg);
                    }
                    // console.log(res)
                }).catch((e) => setLoadingAllot(false))

            }}>替换</Button>

            <div>
                <Space>
                    <CommonSelect
                        url={"/gateway/select"}
                        placeholder="请选择网关"
                        onChange={(value) => {
                            setOldGatewayId(value);
                            getPhoneDateList(value);
                        }}/>
                </Space>
            </div>

            <Row>
                <Col span={12}>
                    <TextArea rows={4} placeholder="旧号码" onChange={(e) => {
                        if (e.target.value === "") {
                            return
                        }
                        let split = e.target.value.split('\n');
                        if (split.length <= 0) {
                            return;
                        }
                        for (let i = 0; i < split.length; i++) {
                            split[i] = split[i].replace(/["\s\t]/g, '')
                        }
                        setOldPhoneData(split)
                    }}/>
                </Col>
                <Col span={12}>
                    <TextArea rows={4} placeholder="新号码" onChange={(e) => {
                        if (e.target.value === "") {
                            return
                        }
                        let split = e.target.value.split('\n');
                        if (split.length <= 0) {
                            return;
                        }
                        for (let i = 0; i < split.length; i++) {
                            split[i] = split[i].replace(/["\s\t]/g, '')
                        }
                        setNewPhoneData(split)
                    }}/>
                </Col>
            </Row>
            <ShowTo></ShowTo>
        </div>
    );
}

export default ReplaceReallyPhone
