import {
    Button, Input,
    message, notification,
    Select,
    Space, Switch,
    Collapse,
    Divider,
    Transfer
} from 'antd';
import React, {useState, useEffect} from 'react';
import AppAxios from "../../utils/request";
import CommonSelect from "../../components/Common/Select";
import {SearchOutlined} from "@ant-design/icons";

const {Panel} = Collapse;
const AllotPhone = () => {
    const [loadingAllot, setLoadingAllot] = useState(false);
    const [loadingPhones, setLoadingPhones] = useState(true);
    const [loadingExtensions, setLoadingExtensions] = useState(true);

    const [isCycle, setIsCycle] = useState(true);


    const [phoneTableData, setPhoneTableData] = useState([]);
    const [extensionTableData, setExtensionTableData] = useState([]);

    const [searchGatewayId, setSearchGatewayId] = useState("");
    const [searchIsUse, setSearchIsUse] = useState(0);
    const [searchNumber, setSearchNumber] = useState("");
    const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
    const [searchPhoneAttribution, setSearchPhoneAttribution] = useState("");

    const [searchOrganId, setSearchOrganId] = useState("");
    const [searchPhoneId, setSearchPhoneId] = useState("");


    const getPhoneDateList = () => {
        AppAxios.get("/phones", {
            params: {
                page: 1,
                pageSize: 500,
                gatewayId: searchGatewayId,
                isUse: searchIsUse,
            }
        }).then(res => {
            setLoadingPhones(false);
            if ((res.code === 2000 || res.code === 0)) {
                setPhoneTableData(res.data.list)
            }
        }).catch((e) => setLoadingPhones(false))
    }
    const getExtensionDateList = () => {
        AppAxios.get("/extensions", {
            params: {
                page: 1,
                pageSize: 500,
                organId: searchOrganId,
                phoneId: searchPhoneId,
                number: searchNumber,
                phoneNumber: searchPhoneNumber,
                phoneAttribution: searchPhoneAttribution,
            }
        }).then(res => {
            setLoadingExtensions(false);
            if ((res.code === 2000 || res.code === 0)) {
                setExtensionTableData(res.data.list)
            }
        }).catch((e) => setLoadingExtensions(false))
    }


    useEffect(() => {
        if (loadingPhones) {
            getPhoneDateList()
        }
        if (loadingExtensions) {
            getExtensionDateList()
        }
    }, [loadingAllot, loadingPhones, loadingExtensions]);

    const [phoneTargetKeys, setPhoneTargetKeys] = useState([]);
    const [phoneSelectedKeys, setPhoneSelectedKeys] = useState([]);
    const renderPhoneItem = (item) => {
        const customLabel = (
            <span className="custom-item">
            {item.gatewayName}-{item.number}({item.attribution ? item.attribution : '未知'})-{item.useNum}
            </span>
        );
        return {
            label: customLabel,
            value: item.id,
        };
    };


    const [extensionTargetKeys, setExtensionTargetKeys] = useState([]);
    const [extensionSelectedKeys, setExtensionSelectedKeys] = useState([]);
    const renderExtensionItem = (item) => {
        const customLabel = (
            <span className="custom-item">
            {item.organName}-{item.number}-{item.phoneNumber}({item.phoneAttribution ? item.phoneAttribution : '未知'})
            </span>
        );
        return {
            label: customLabel, // for displayed item
            value: item.id, // for title and filter matching
        };
    };
    return (
        <div>
            <h2>号码分配</h2>

                <Button key="btn-allot" type="primary" loading={loadingAllot} onClick={() => {
                    setLoadingAllot(true)
                    if (phoneTargetKeys.length <= 0 || extensionTargetKeys.length <= 0) {
                        setLoadingAllot(false)
                        message.warning('请选择号码');
                        return;
                    }
                    let extensionId = [];
                    let phoneId = [];
                    phoneTargetKeys.forEach(function (value, index) {
                        phoneId.push(parseInt(value.replace("p", "")))
                    });
                    extensionTargetKeys.forEach(function (value, index) {
                        extensionId.push(parseInt(value.replace("e", "")))
                    });
                    AppAxios.post("/phone/allot", {
                        isCycle: isCycle ? 1 : 2,
                        extensionIds: extensionId,
                        phoneIds: phoneId,
                    }).then(res => {
                        setLoadingAllot(false)
                        if ((res.code === 2000 || res.code === 0)) {
                            notification["success"]({
                                message: "操作成功",
                                description: "操作成功",
                            });
                            setPhoneTargetKeys([]);
                            setExtensionTargetKeys([]);
                            setPhoneSelectedKeys([]);
                            setExtensionSelectedKeys([]);
                            setLoadingPhones(true)
                            setLoadingExtensions(true)
                        } else {
                            message.warning(res.msg);
                        }
                        console.log(res)
                    }).catch((e) => setLoadingExtensions(false))

                }}>分配</Button>

                <Collapse ghost>
                    <Panel header="使用说明" key="1">
                        <p>
                            <strong>第一步·选择循环分配:</strong>
                            循环分配的意思是，如果选择的号码少于选择的分机,那么这个号码将会循环分配给每一个坐席，以保证每个坐席都能被分配到号码，举个栗子：
                            <ol>
                                <li>选择1个号码，n个坐席：开启循环分配：所有坐席都会被分配到这个号码</li>
                                <li>选择1个号码，n个坐席：关闭循环分配：只有第一个坐席会被分配到这个号码</li>
                                <li>选择n个号码，1个坐席或者选择n个号码，n个坐席：此时是否开启关闭循环分配没有意义</li>
                            </ol>
                        </p>
                        <p>
                            <strong>第二步·选择要分配的号码:</strong>

                            <ol>
                                <li>选择的时候，首先选择搜索条件，然后点击搜索按钮</li>
                                <li>把需要分配的号码选中，并移动到右侧要分配的号码池中</li>
                                <li>注意：这时候可以重新选择筛选条件，然后进行搜索。此时你会发现，右侧已经选择好了的号码会消失，别担心。只需要你最后，清除所有筛选条件即可</li>
                            </ol>
                        </p>
                        <p>
                            <strong>第三步·选择要被分配的坐席:</strong>
                        </p>
                        <p>
                            <strong>第四步·点击右上角的分配按钮</strong>
                        </p>
                    </Panel>
                </Collapse>

                <Divider orientation="left" orientationMargin="0">选择分配模式:</Divider>


                <div>循环分配:<Switch checked={isCycle} onChange={() => {
                    setIsCycle(!isCycle)
                }}/></div>

                <Divider orientation="left" orientationMargin="0">选择分配号码:</Divider>

                <div style={{
                    lineHeight: 3,
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    <Space>
                        <CommonSelect
                            url={"/gateway/select"}
                            placeholder="请选择网关"
                            onChange={(value) => {
                                setSearchGatewayId(value);
                            }}/>
                        <Select
                            allowClear
                            placeholder="请选择是否使用"
                            style={{minWidth: 130}}
                            onChange={(value) => {
                                setSearchIsUse(value);
                            }}>
                            <Select.Option key="1" value={1}>已使用</Select.Option>
                            <Select.Option key="2" value={-1}>未使用</Select.Option>
                        </Select>
                        <Button onClick={() => {
                            setLoadingPhones(true);
                        }} type="primary" icon={<SearchOutlined/>}>筛选</Button>
                    </Space>
                </div>

                <Transfer
                    dataSource={phoneTableData}
                    titles={['可分配的号码', '待分配的号码']}
                    rowKey={record => "p" + record.id}
                    listStyle={{
                        width: 350,
                        height: 300,
                    }}
                    targetKeys={phoneTargetKeys}
                    selectedKeys={phoneSelectedKeys}
                    onChange={(nextTargetKeys, direction, moveKeys) => {
                        setPhoneTargetKeys(nextTargetKeys);
                    }}
                    onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
                        console.log('sourceSelectedKeys:', sourceSelectedKeys);
                        console.log('targetSelectedKeys:', targetSelectedKeys);
                        setPhoneSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
                    }}
                    render={renderPhoneItem}
                />

                <Divider orientation="left" orientationMargin="0">选择坐席号码:</Divider>

                <div style={{
                    lineHeight: 3,
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    <Space>
                        <CommonSelect
                            url={"/organ/select"}
                            placeholder="请选择机构"
                            onChange={(value) => {
                                setSearchOrganId(value);
                            }}/>
                        <CommonSelect
                            style={{width: 240}}
                            placeholder="请选择号码"
                            url={"/phones/select"}
                            onChange={(value) => {
                                setSearchPhoneId(value);
                            }}/>
                        <Input allowClear placeholder="分机号" style={{width: '120px'}} onChange={(e) => {
                            setSearchNumber(e.target.value)
                        }}/>
                        <Input allowClear placeholder="外显号" style={{width: '120px'}} onChange={(e) => {
                            setSearchPhoneNumber(e.target.value)
                        }}/>
                        <Input allowClear placeholder="归属地" style={{width: '120px'}} onChange={(e) => {
                            setSearchPhoneAttribution(e.target.value)
                        }}/>
                        <Button onClick={() => {
                            setLoadingExtensions(true);
                        }} type="primary" icon={<SearchOutlined/>}>筛选</Button>
                    </Space>
                </div>

                <Transfer
                    dataSource={extensionTableData}
                    titles={['可分配的坐席', '待分配的坐席']}
                    rowKey={record => "e" + record.id}
                    listStyle={{
                        width: 350,
                        height: 300,
                    }}
                    targetKeys={extensionTargetKeys}
                    selectedKeys={extensionSelectedKeys}
                    onChange={(nextTargetKeys, direction, moveKeys) => {
                        setExtensionTargetKeys(nextTargetKeys);
                    }}
                    onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
                        console.log('sourceSelectedKeys:', sourceSelectedKeys);
                        console.log('targetSelectedKeys:', targetSelectedKeys);
                        setExtensionSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
                    }}
                    render={renderExtensionItem}
                />

        </div>
    );
}

export default AllotPhone
