import {Button, Space, Table, DatePicker, Input, Modal, Descriptions, InputNumber} from 'antd';
import React, {useState, useEffect} from 'react';
import AppAxios from "../../utils/request";
import CommonPagination from "../../components/Common/Pagination";
import CommonSelect from "../../components/Common/Select";
import {SearchOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import AudioPlayer from "../../components/Common/Player";


const {RangePicker} = DatePicker;


const Cdr = () => {


    const columns = [
        {title: '主叫', width: 70, dataIndex: 'caller', key: 'caller',},
        {title: '被叫', width: 70, dataIndex: 'callee', key: 'callee'},
        {
            title: '业务号码',
            width: 120,
            dataIndex: 'extensionNumber',
            key: 'extensionNumber',
            render: (_, record) => (<div>
                <span>分机号:{record.extensionNumber}</span><br/>
                <span>出局号:{record.outPhone}</span>
            </div>),
        },
        {
            title: '类型', width: 90, key: 'callType', dataIndex: 'callType', render: (_, record) => {
                let callType;
                let direction;
                switch (record.callType) {
                    case 1:
                        callType = "直呼";
                        break;
                    case 2:
                        callType = "回呼";
                        break;
                    case 3:
                        callType = "机器人";
                        break;
                    default:
                        callType = "未知";
                }
                switch (record.direction) {
                    case 1:
                        direction = "呼出";
                        break;
                    case 2:
                        direction = "呼入";
                        break;
                    default:
                        direction = "未知";
                }
                return (<span>{callType}-{direction}</span>)
            },
        }, {
            title: '通话时间', width: 180, dataIndex: 'createTime', key: 'createTime', render: (_, record) => (<div>
                <span>拨打:{record.createTime}</span><br/>
                <span>应答:{record.answerTime}</span><br/>
                <span>结束:{record.endTime}</span>
            </div>),
        }, {
            title: '时长', width: 60, dataIndex: 'callDuration', key: 'callDuration', render: (_, record) => (<div>
                <span>拨打:{record.callDuration}</span><br/>
                <span>通话:{record.talkDuration}</span>
            </div>),
        }, {
            title: '挂机原因',
            width: 70,
            key: 'hangupCause',
            render: (row, _) => (<span>{row.hangupCause}({row.hangupCode})</span>),
        }, {
            title: '操作', key: 'action', width: 70, render: (row, _) => (<Button
                type="primary"
                size="small"
                onClick={() => {
                    showCdrDetails(row.uuid)
                }}>详情</Button>),
        },];

    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);

    const [tableData, setTableData] = useState([]);

    const [searchOrganId, setSearchOrganId] = useState("");
    const [searchCallee, setSearchCallee] = useState("");
    const [searchCaller, setSearchCaller] = useState("");
    const [searchOutPhone, setSearchOutPhone] = useState("");
    const [searchExtensionNumber, setSearchExtensionNumber] = useState("");
    const [searchStartDatetime, setSearchStartDatetime] = useState("");
    const [searchEndDatetime, setSearchEndDatetime] = useState("");
    const [searchHangupCode, setSearchHangupCode] = useState("");
    const [searchCallDurationStart, setSearchCallDurationStart] = useState("");
    const [searchCallDurationEnd, setSearchCallDurationEnd] = useState("");
    const [searchTalkDurationStart, setSearchTalkDurationStart] = useState("");
    const [searchTalkDurationEnd, setSearchTalkDurationEnd] = useState("");

    //详情弹窗
    const [isModalOpenDetails, setIsModalOpenDetails] = useState(false);
    const [cdrDetails, setCdrDetails] = useState("");
    const [cdrAxbDetails, setCdrAxbDetails] = useState("");


    const showCdrDetails = (uuid) => {
        setCdrDetails("");
        setCdrAxbDetails("");
        AppAxios.get("/cdr/" + uuid + "/details", {}).then(res => {
            setLoading(false);
            if ((res.code === 2000 || res.code === 0)) {
                setCdrDetails(res.data);
                setIsModalOpenDetails(true);
            }
        }).catch((e) => {

        })
        AppAxios.get("/cdr/" + uuid + "/details/axb", {}).then(res => {
            setLoading(false);
            if ((res.code === 2000 || res.code === 0)) {
                setCdrAxbDetails(res.data);
            }
        }).catch((e) => {

        })
    };

    const getDateList = () => {
        AppAxios.get("/cdrs", {
            params: {
                page: current,
                pageSize: pageSize,
                organId: searchOrganId,
                callee: searchCallee,
                caller: searchCaller,
                outPhone: searchOutPhone,
                extensionNumber: searchExtensionNumber,
                startDatetime: searchStartDatetime,
                endDatetime: searchEndDatetime,
                hangupCode: searchHangupCode,
                callDurationStart: searchCallDurationStart,
                callDurationEnd: searchCallDurationEnd,
                talkDurationStart: searchTalkDurationStart,
                talkDurationEnd: searchTalkDurationEnd,
            }
        }).then(res => {
            setLoading(false);
            if ((res.code === 2000 || res.code === 0)) {
                setTableData(res.data.list)
                setTotal(res.data.page.total)
            }
        }).catch((e) => setLoading(false))
    }

    useEffect(() => {
        console.log("=====>useEffect", loading, cdrDetails, isModalOpenDetails)
        if (loading) {
            getDateList()
        }
    }, [loading, current, pageSize, cdrDetails, isModalOpenDetails]);

    function ShowReocrd() {
        if (cdrDetails.talkDuration > 0) {
            return (<AudioPlayer src={cdrDetails.recordPath}/>)
        } else {
            return ("无录音")
        }
    }

    function ShowAxbNumber() {
        if (cdrAxbDetails) {
            return ("(X号:"+cdrAxbDetails.inboundCallee+")")
        } else {
            return ("")
        }
    }

    return (<div>

        <h3>话单管理</h3>

        <div style={{
            lineHeight: 3, display: "flex", justifyContent: "space-between",
        }}>
            <Space>
                <CommonSelect
                    url={"/organ/select"}
                    placeholder="请选择机构"
                    onChange={(value) => {
                        setSearchOrganId(value);
                        setCurrent(1);
                        setLoading(true);
                    }}/>
                <RangePicker showTime
                             presets={[
                                 {label: '今天', value: [dayjs().startOf('day'), dayjs()]},
                                 {
                                     label: '昨天',
                                     value: [dayjs().subtract(1, 'day').startOf('day'), dayjs().startOf('day')]
                                 },
                                 {label: '本周', value: [dayjs().startOf('week'), dayjs().endOf('week')]},
                                 {
                                     label: '上周',
                                     value: [dayjs().subtract(1, 'week').startOf('week').startOf('day'), dayjs().subtract(1, 'week').endOf('week').endOf('day')]
                                 },
                                 {label: '本月', value: [dayjs().startOf('month'), dayjs().endOf('month')]},
                             ]}
                             placeholder={["开始日期", "结束日期"]}
                             onChange={(date1, date2) => {
                                 setSearchStartDatetime(date2[0]);
                                 setSearchEndDatetime(date2[1]);
                                 setCurrent(1);
                                 setLoading(true);
                             }}/>

                <Input allowClear placeholder="主叫号码" style={{width: '120px'}} onChange={(e) => {
                    setSearchCaller(e.target.value)
                }}/>
                <Input allowClear placeholder="被叫号码" style={{width: '120px'}} onChange={(e) => {
                    setSearchCallee(e.target.value)
                }}/>

            </Space>
        </div>
        <div style={{
            lineHeight: 3, display: "flex", justifyContent: "space-between", marginBottom: "5px"
        }}><Space>
            <Input allowClear placeholder="外显号码" style={{width: '120px'}} onChange={(e) => {
                setSearchOutPhone(e.target.value)
            }}/>
            <Input allowClear placeholder="挂机码" style={{width: '120px'}} onChange={(e) => {
                setSearchHangupCode(e.target.value)
            }}/>
            <Input.Group compact>
                <Input
                    className="site-input-split"
                    style={{
                        width: 80,
                        borderLeft: 0,
                        borderRight: 0,
                        pointerEvents: 'none',
                    }}
                    placeholder="通话时长"
                    disabled
                />
                <InputNumber
                    allowClear
                    style={{
                        width: 100,
                        textAlign: 'center',
                    }}
                    placeholder="最小"
                    onChange={(e) => {
                        setSearchTalkDurationStart(e)
                    }}
                />
                <Input
                    className="site-input-split"
                    style={{
                        width: 30,
                        borderLeft: 0,
                        borderRight: 0,
                        pointerEvents: 'none',
                    }}
                    placeholder="~"
                    disabled
                />
                <InputNumber
                    allowClear
                    className="site-input-right"
                    style={{
                        width: 100,
                        textAlign: 'center',
                    }}
                    placeholder="最大"
                    onChange={(e) => {
                        setSearchTalkDurationEnd(e)
                    }}
                />

            </Input.Group>
            <Button     style={{
                borderLeft: 3,
                borderRight: 0,
            }} onClick={() => {
                setCurrent(1);
                setLoading(true);
            }} type="primary" icon={<SearchOutlined/>}>搜索</Button>

            <Button
                type="primary"
                onClick={() => {
                    let token = window.localStorage.getItem("token");

                    // 构建查询参数对象
                    const params = {
                        token: token,
                        page: current,
                        pageSize: pageSize,
                        organId: searchOrganId,
                        callee: searchCallee,
                        caller: searchCaller,
                        outPhone: searchOutPhone,
                        extensionNumber: searchExtensionNumber,
                        startDatetime: searchStartDatetime,
                        endDatetime: searchEndDatetime,
                        hangupCode: searchHangupCode,
                        callDurationStart: searchCallDurationStart,
                        callDurationEnd: searchCallDurationEnd,
                        talkDurationStart: searchTalkDurationStart,
                        talkDurationEnd: searchTalkDurationEnd,
                        rand:  Date.now(),
                    };

                    // 过滤掉空值、null 和 undefined
                    const filteredParams = Object.fromEntries(
                        Object.entries(params).filter(([_, value]) =>
                            value !== null && value !== undefined && value !== ''
                        )
                    );

                    console.log(filteredParams);
                    // 构建查询字符串
                    const queryString = new URLSearchParams(filteredParams).toString();

                    // 基础URL
                    const baseUrl = '/backend/cdrs:export';

                    // 组合完整URL
                    const fullUrl = `${baseUrl}?${queryString}`;

                    // 跳转
                    window.location.href = fullUrl;


                }}>
                导出
            </Button>
        </Space>
        </div>


        <Table
            bordered
            scroll={{x: 1300}}
            columns={columns}
            dataSource={tableData}
            rowKey={columns => columns.id}
            loading={loading}
            pagination={false}
            footer={() => (<CommonPagination
                onChange={(page) => {
                    setCurrent(page);
                    setLoading(true);
                }}
                onShowSizeChange={(current, size) => {
                    setCurrent(1);
                    setPageSize(size);
                    setLoading(true);
                }}
                current={current}
                total={total}
                size={pageSize}
            />)}
        />


        <Modal title="话单详情" open={isModalOpenDetails} onOk={() => {
            setIsModalOpenDetails(false)
        }}
               onCancel={() => {
                   setIsModalOpenDetails(false)
               }}>
            <Descriptions column={2}>
                <Descriptions.Item label="uuid" span={2}>{cdrDetails.uuid}</Descriptions.Item>
                <Descriptions.Item label="IP">{cdrDetails.sourceIp}</Descriptions.Item>
                <Descriptions.Item></Descriptions.Item>
                <Descriptions.Item label="呼叫类型">{cdrDetails.callType}</Descriptions.Item>
                <Descriptions.Item label="呼叫方向">{cdrDetails.direction}</Descriptions.Item>
                <Descriptions.Item label="呼叫时间">{cdrDetails.createTime}</Descriptions.Item>
                <Descriptions.Item label="应答时间">{cdrDetails.answerTime}</Descriptions.Item>
                <Descriptions.Item label="结束时间">{cdrDetails.endTime}</Descriptions.Item>
                <Descriptions.Item></Descriptions.Item>

                <Descriptions.Item label="主叫">{cdrDetails.caller}</Descriptions.Item>
                <Descriptions.Item label="被叫">{cdrDetails.callee}<ShowAxbNumber/></Descriptions.Item>
                <Descriptions.Item label="出局号码">{cdrDetails.outPhone}</Descriptions.Item>
                <Descriptions.Item label="分机号">{cdrDetails.extensionNumber}</Descriptions.Item>

                <Descriptions.Item label="挂机原因">{cdrDetails.hangupCause}</Descriptions.Item>
                <Descriptions.Item label="挂机代码">{cdrDetails.hangupCode}</Descriptions.Item>

                <Descriptions.Item label="通话时长">{cdrDetails.callDuration}</Descriptions.Item>
                <Descriptions.Item label="录音时长">{cdrDetails.talkDuration}</Descriptions.Item>
                <Descriptions.Item label="录音"></Descriptions.Item>
            </Descriptions>
            <ShowReocrd></ShowReocrd>
        </Modal>
    </div>);
}

export default Cdr
