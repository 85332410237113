import {
    Button,
    Form,
    Input,
    Modal,
    notification, Popconfirm, Radio,
    Space,
    Table, Tag
} from 'antd';
import React, {useState, useEffect} from 'react';
import AppAxios from "../../utils/request";
import CommonPagination from "../../components/Common/Pagination";



const GatewayList = () => {
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);

    const [tableData, setTableData] = useState([]);

    const [addModalVisible, setAddModalVisible] = useState(false);
    const [formAdd] = Form.useForm();


    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => <span>{record.name}({record.id})</span>,
        },
        {
            title: 'host',
            width: 300,
            dataIndex: 'host',
            key: 'host',
        },
        {
            title: '被叫前缀',
            width: 200,
            dataIndex: 'calledPrefix',
            key: 'calledPrefix',
        },
        {
            title: '状态',
            width: 60,
            dataIndex: 'state',
            key: 'state',
            render: (state) => {
                switch (state) {
                    case 1:
                        return (<Tag color="green">启用</Tag>)
                    case 0:
                        return (<Tag color="red">停用</Tag>)
                    default:
                        return (<Tag>未知</Tag>)
                }
            },
        },

        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
        },
        {
            title: '操作',
            width: 150,
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            formAdd.setFieldsValue(record)
                            setAddModalVisible(true)
                        }}>编辑</Button>

                    <Popconfirm
                        title="确定要删除吗"
                        onConfirm={() => {
                            AppAxios.delete("/gateway/" + record.id).then(res => {
                                if ((res.code === 2000 || res.code === 0)) {
                                    getDateList()
                                }
                            }).catch((e) => {

                            })
                        }}
                        okText="删除"
                        cancelText="取消"
                    >
                        <Button
                            type="primary"
                            danger
                            size="small">删除</Button>
                    </Popconfirm>
                </Space>


            ),
        },
    ];

    const getDateList = () => {
        AppAxios.get("/gateway", {
            params: {
                page: current,
                pageSize: pageSize,
            }
        }).then(res => {
            setLoading(false);
            if ((res.code === 2000 || res.code === 0)) {
                setTableData(res.data.list)
                setTotal(res.data.page.total)
            }
        }).catch((e) => setLoading(false))
    }


    useEffect(() => {
        if (loading) {
            getDateList()
        }
    }, [loading, current, pageSize]);


    return (
        <div>
            <h3>网关管理</h3>
                <div style={{
                    lineHeight: 3,
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => setAddModalVisible(true)}>
                            新增
                        </Button>
                    </Space>
                </div>
                <Table
                    bordered
                    columns={columns}
                    dataSource={tableData}
                    rowKey={columns => columns.id}
                    loading={loading}
                    pagination={false}
                    footer={() => (
                        <CommonPagination
                            onChange={(page) => {
                                setCurrent(page);
                                setLoading(true);
                            }}
                            onShowSizeChange={(current, size) => {
                                setCurrent(1);
                                setPageSize(size);
                                setLoading(true);
                            }}
                            current={current}
                            total={total}
                            size={pageSize}
                        />
                    )}
                />

            <Modal title="新增网关" open={addModalVisible}
                   onOk={() => {
                       formAdd.validateFields()
                           .then(values => {
                               if (values.id) {
                                   AppAxios.put("/gateway", values).then(res => {
                                       if ((res.code === 2000 || res.code === 0)) {
                                           formAdd.resetFields();
                                           notification["success"]({
                                               message: "操作成功",
                                               description: "操作成功",
                                           });
                                           setAddModalVisible(false);
                                           getDateList();
                                       }
                                   }).catch((e) => {

                                   })
                               }else {
                                   AppAxios.post("/gateway", values).then(res => {
                                       if ((res.code === 2000 || res.code === 0)) {
                                           formAdd.resetFields();
                                           notification["success"]({
                                               message: "操作成功",
                                               description: "操作成功",
                                           });
                                           setAddModalVisible(false);
                                           getDateList();
                                       }
                                   }).catch((e) => {

                                   })
                               }

                           })
                           .catch(info => {
                               console.log('Validate Failed:', info);
                           });
                   }}
                   onCancel={() => {
                       setAddModalVisible(false)
                   }}>
                <div>
                    <Form
                        form={formAdd}
                        name="basic"
                        labelCol={{span: 4,}}
                        initialValues={{remember: true}}
                        autoComplete="off"
                    >
                        <Form.Item label="id" name="id" hidden={true}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="网关名称"
                            name="name"
                            rules={[{required: true, message: '请输入网关名称'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="网关地址"
                            name="host"
                            rules={[{required: true, message: '请输入网关地址'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="被叫前缀"
                            name="calledPrefix"
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item label="状态" name="state">
                            <Radio.Group>
                                <Radio value={1}>启用</Radio>
                                <Radio value={0}>禁用</Radio>
                            </Radio.Group>
                        </Form.Item>


                        <Form.Item
                            label="备注"
                            name="remark"
                        >
                            <Input/>
                        </Form.Item>

                    </Form>
                </div>
            </Modal>
        </div>
    );
}

export default GatewayList
