import {
    Button,
    Form,
    Input,
    Modal,
    notification,
    Popconfirm, Select,
    Space,
    Table
} from 'antd';
import React, {useState, useEffect} from 'react';
import AppAxios from "../../utils/request";
import CommonPagination from "../../components/Common/Pagination";
import CommonSelect from "../../components/Common/Select";
import {SearchOutlined} from "@ant-design/icons";
import {message, Upload} from 'antd';
import {InboxOutlined} from '@ant-design/icons';

const {Dragger} = Upload;

const PhoneList = () => {
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);

    const [tableData, setTableData] = useState([]);


    const [batchAddModalVisible, setBatchAddModalVisible] = useState(false);
    const [formBatchAdd] = Form.useForm();
    const [fileList, setFileList] = useState([]);

    const [searchGatewayId, setSearchGatewayId] = useState("");
    const [searchIsUse, setSearchIsUse] = useState(0);
    const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
    const [searchPhoneAttribution, setSearchPhoneAttribution] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const columns = [
        {
            title: '机构',
            dataIndex: 'gatewayName',
            key: 'gatewayName',
            render: (text) => <a>{text}</a>,
        },
        {
            title: '归属地',
            dataIndex: 'attribution',
            key: 'attribution',
        },
        {
            title: '外显号码',
            width: 100,
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '使用数',
            width: 90,
            dataIndex: 'useNum',
            key: 'useNum',
        },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm
                        title="确定要删除吗"
                        onConfirm={() => {
                            AppAxios.post("/phones/delete", [record.id]).then(res => {
                                if ((res.code === 2000 || res.code === 0)) {
                                    notification["success"]({
                                        message: "操作成功",
                                        description: "操作成功",
                                    });
                                    getDateList();
                                }
                            }).catch((e) => {
                            })
                        }}
                        okText="删除"
                        cancelText="取消"
                    >
                        <Button
                            type="primary"
                            danger
                            size="small">删除</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const getDateList = () => {
        AppAxios.get("/phones", {
            params: {
                page: current,
                pageSize: pageSize,
                gatewayId: searchGatewayId,
                isUse: searchIsUse,
                number: searchPhoneNumber,
                attribution: searchPhoneAttribution,
            }
        }).then(res => {
            setLoading(false);
            if ((res.code === 2000 || res.code === 0)) {
                setTableData(res.data.list)
                setTotal(res.data.page.total)
            }
        }).catch((e) => setLoading(false))
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            console.log(`selectedRowKeys: ${selectedRowKeys}`);
            setSelectedRowKeys(selectedRowKeys);
        },
    };


    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    useEffect(() => {
        if (loading) {
            getDateList()
        }
    }, [loading, current, pageSize]);


    return (
        <div>
            <h3>号码管理</h3>

            <Button
                type="primary"
                onClick={() => setBatchAddModalVisible(true)}>
                批量新增
            </Button>

            <Popconfirm
                title="确定要删除吗"
                onConfirm={() => {
                    AppAxios.post("/phones/delete", selectedRowKeys).then(res => {
                        if ((res.code === 2000 || res.code === 0)) {
                            notification["success"]({
                                message: "操作成功",
                                description: "操作成功",
                            });
                            getDateList();
                        }

                    }).catch((e) => {
                    })
                }}
                okText="删除"
                cancelText="取消"
            >
                <Button
                    type="primary"
                    danger>批量删除</Button>
            </Popconfirm>

            <div style={{
                lineHeight: 3,
                display: "flex",
                justifyContent: "space-between",
            }}>
                <Space>
                    <CommonSelect
                        url={"/gateway/select"}
                        placeholder="请选择网关"
                        onChange={(value) => {
                            setSearchGatewayId(value);
                            setCurrent(1);
                            setLoading(true);
                        }}/>
                    <Select
                        allowClear
                        placeholder="请选择是否使用"
                        style={{minWidth: 130}}
                        onChange={(value) => {
                            setSearchIsUse(value);
                            setCurrent(1);
                            setLoading(true);
                        }}>
                        <Select.Option key="1" value={1}>已使用</Select.Option>
                        <Select.Option key="2" value={-1}>未使用</Select.Option>
                    </Select>

                    <Input allowClear placeholder="号码" style={{width: '160px'}} onChange={(e) => {
                        setSearchPhoneNumber(e.target.value)
                    }}/>
                    <Input allowClear placeholder="归属地" style={{width: '120px'}} onChange={(e) => {
                        setSearchPhoneAttribution(e.target.value)
                    }}/>


                    <Button onClick={() => {
                        setCurrent(1);
                        setLoading(true);
                    }} type="primary" icon={<SearchOutlined/>}>搜索</Button>
                </Space>
            </div>


            <Table
                bordered
                columns={columns}
                dataSource={tableData}
                rowKey={columns => columns.id}
                loading={loading}
                pagination={false}
                rowSelection={rowSelection}
                footer={() => (
                    <CommonPagination
                        onChange={(page) => {
                            setCurrent(page);
                            setLoading(true);
                        }}
                        onShowSizeChange={(current, size) => {
                            setCurrent(1);
                            setPageSize(size);
                            setLoading(true);
                        }}
                        current={current}
                        total={total}
                        size={pageSize}
                    />
                )}
            />


            <Modal title="批量导入号码" visible={batchAddModalVisible}
                   onOk={() => {
                       formBatchAdd.validateFields()
                           .then(values => {
                               const formData = new FormData();
                               if (fileList.length > 0) {
                                   formData.set("file", fileList[0])
                               } else {
                                   formData.append("phones", values.phones.replace(/["\t ]/g, ''))
                               }
                               formData.append("gatewayId", values.gatewayId)
                               AppAxios.post("/phones", formData).then(res => {
                                   if ((res.code === 2000 || res.code === 0)) {
                                       formBatchAdd.resetFields();
                                       notification["success"]({
                                           message: "操作成功",
                                           description: "操作成功",
                                       });
                                       setBatchAddModalVisible(false);
                                       setFileList([]);
                                       getDateList();
                                   }
                               }).catch((e) => {
                               })
                           })
                           .catch(info => {
                               console.log('Validate Failed:', info);
                           });
                   }}
                   onCancel={() => {
                       setBatchAddModalVisible(false)
                   }}>
                <div>
                    <Form
                        form={formBatchAdd}
                        name="basic"
                        labelCol={{span: 2,}}
                        initialValues={{remember: true}}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="网关"
                            name="gatewayId"
                            rules={[{required: true, message: '请选择归属网关'}]}
                        >
                            <CommonSelect url={"/gateway/select"}/>
                        </Form.Item>


                        <Form.Item
                            label="号码"
                            name="phones"
                            rules={[{required: false, message: '请输入电话号码'}]}
                        >
                            <Input.TextArea rows={6} placeholder="请输入[归属地,电话号码]或[电话号码]。多个号码请换行"/>
                        </Form.Item>

                        <Form.Item
                            label="CSV"
                            name="file"
                            rules={[{required: false, message: '请输入电话号码'}]}
                        >
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined/>
                                </p>
                                <p className="ant-upload-text">点击或者托拽文件到这里</p>
                                <p className="ant-upload-hint">
                                    支持:<br/>
                                    1、号码导出列表.csv<br/>
                                    2、号码、城市名称列
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>


        </div>
    );
}

export default PhoneList
