import React from 'react';

const Me = () => {
    return (
        <div>
            <h3>个人中心</h3>
        </div>
    );
}

export default Me
