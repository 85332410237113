import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import {Routes, Route} from "react-router-dom"
import BackendLayout from "./views/layout";
import Login from "./views/login";
import CustomRouter from "./utils/CustomRouter";

import {customHistory} from './utils/history'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CustomRouter history={customHistory}>
        <Routes>
            <Route path="login" element={<Login/>}/>
            <Route path="/*" element={<BackendLayout/>}/>
        </Routes>
    </CustomRouter>
);
