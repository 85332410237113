import {Select} from "antd";
import {useState} from "react";
import AppAxios from "../../utils/request";
import {useEffect} from "react";

const {Option} = Select;


const CommonSelect = props => {
    const [selectData, setSelectData] = useState([]);
    const getDateList = () => {
        AppAxios.get(props.url).then(res => {
            if ((res.code === 2000 || res.code === 0)) {
                setSelectData(res.data)
            }
        }).catch((e) => {

        })
    }

    useEffect(() => {
        getDateList()
    }, []);
    return (
        <Select
            showSearch
            allowClear
            optionFilterProp="children"
            placeholder={props.placeholder}
            value={props.value}
            style={{minWidth: 130, ...props.style}}
            onChange={props.onChange}>
            {selectData.length > 0 ? selectData.map(v => {
                return (<Option key={v.itemKey} value={v.itemKey}>{v.itemLabel}</Option>)
            }) : null}
        </Select>
    )
}
export default CommonSelect
