import React, {useState, useEffect, useRef} from 'react';
import {Button} from 'antd';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline';

const createWaveSurfer = (waveform, timeline = true) => {
    const plugins = [];
    if (timeline) {
        plugins.push(TimelinePlugin.create({container: '.wave-timeline'}));
    }

    const wavesurfer = WaveSurfer.create({
        autoCenter: true,
        backgroundColor: '#89B',
        barWidth: 1,
        container: waveform,
        cursorColor: '#800',
        cursorWidth: 3,
        height: 80,
        hideScrollbar: false,
        normalize: true,
        plugins,
        progressColor: '#2347fc',
        scrollParent: true,
        splitChannels: true,
        waveColor: '#5086d8',
        xhr: {withCredentials: true},
    });

    return wavesurfer;
};

const AudioPlayer = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [hasError, setHasError] = useState(false);
    const waveSurferRef = useRef(null);
    const waveformRef = useRef(null);

    const onPlayerReady = () => {
        setIsLoading(false);
        setHasError(false);
        waveSurferRef.current.seekTo(props.cursorInitPos);
        if (props.onCursorChanged) {
            waveSurferRef.current.on('seek', onSeek);
            waveSurferRef.current.on('pause', onSeek);
            onSeek();
        }
    };

    const onFinishPlay = () => {
        setIsPlaying(false);
    };

    const onPlayerError = () => {
        setHasError(true);
    };

    const onSeek = () => {
        const duration = waveSurferRef.current.getDuration();
        const currentTime = waveSurferRef.current.getCurrentTime();
        props.onCursorChanged(duration, currentTime);
    };

    useEffect(() => {
        const waveform = waveformRef.current.querySelector('.wave');
        const wavesurfer = createWaveSurfer(waveform, props.timeline);
        waveSurferRef.current = wavesurfer;

        wavesurfer.load(props.src);
        wavesurfer.on('ready', onPlayerReady);
        wavesurfer.on('finish', onFinishPlay);
        wavesurfer.on('error', onPlayerError);

        return () => {
            wavesurfer.unAll();
            wavesurfer.destroy();
        };
    }, [props.src]);

    const play = () => {
        waveSurferRef.current.playPause();
        setIsPlaying(prevIsPlaying => !prevIsPlaying);
    };

    const onZoom = (v) => {
        waveSurferRef.current.zoom(v);
    };

    const buttonLabel = isPlaying ? "暂停" : "播放";
    const buttonDisabled = hasError;

    return (
        <div ref={waveformRef} className="waveform center-xs middle-xs">
            <div className="wave"/>
            <div className="wave-timeline"/>
            {!isLoading && (
                <div className="row center">
                    <Button type="primary" disabled={buttonDisabled} onClick={play}>{buttonLabel}</Button>
                </div>
            )}
        </div>
    );
};

AudioPlayer.propTypes = {
    src: PropTypes.object.isRequired,
    timeline: PropTypes.bool,
    onCursorChanged: PropTypes.func,
    cursorInitPos: PropTypes.number,
};

AudioPlayer.defaultProps = {
    timeline: false,
    onCursorChanged: null,
    cursorInitPos: 0,
};

export default AudioPlayer;