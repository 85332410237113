import {Button, ConfigProvider, Layout, Menu, theme} from 'antd';
import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";


import Home from "./backend/home";
import Extension from "./backend/extension";
import Cdr from "./backend/cdr";


//设置本地语言
import zhCN from 'antd/es/locale/zh_CN';
import PhoneList from "./backend/phone";
import OrganList from "./backend/organ";
import GatewayList from "./backend/gateway";
import AllotPhone from "./backend/allot";
import Stat from "./backend/stat";
import ReplacePhone from "./backend/replace";
import ReplaceReallyPhone from "./backend/replaceReally";
import Me from "./backend/me";

import dayjs from 'dayjs';
import dayjsLocal from 'dayjs/locale/zh-cn';
import AppAxios from "../utils/request";


import {customHistory} from "../utils/history";

dayjs.locale(dayjsLocal)


const {Header, Content, Footer, Sider} = Layout;


const BackendLayout = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const [current, setCurrent] = useState(location.pathname.substring(1));

    const [collapsed, setCollapsed] = useState(false);
    const [menus, setMenus] = useState([]);
    const {token: {colorBgContainer}} = theme.useToken();


    const onClick = (e) => {
        setCurrent(e.key);
        navigate(`/${e.key}`);
    };

    const getDateList = () => {
        AppAxios.get("/menus", {params: {}}).then(res => {
            setMenus(res.data);
        })
    }

    useEffect(() => {
        getDateList()
    }, []);

    return (
        <ConfigProvider
            theme={{
                token: {
                    "wireframe": false,
                    "borderRadius": 3
                },
            }}
            locale={zhCN}>
            <Layout>
                <Sider width={120} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                    <div
                        style={{
                            height: 32,
                            margin: 16,
                            background: 'rgba(255, 255, 255, 0)',
                        }}
                    />
                    <Menu
                        theme="dark"
                        mode="inline"
                        selectedKeys={[current]}
                        onClick={onClick}
                        defaultSelectedKeys={['4']}
                        items={menus}
                    />
                </Sider>
                <Layout className="site-layout">
                    <Header style={{
                        marginBottom: 10,

                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",

                        paddingRight: 16,

                        height: 40,
                        background: colorBgContainer
                    }}>

                        <Button type="link" onClick={() => {
                            customHistory.push("/me");
                        }}>个人中心</Button>

                        <Button type="link" onClick={() => {
                            window.localStorage.setItem("token", "")
                            customHistory.push("/login");
                        }}>退出</Button>


                    </Header>
                    <Content style={{margin: '0 10px'}}>
                        <div style={{padding: 10, minHeight: 600, background: colorBgContainer}}>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/home" element={<Home/>}/>
                                <Route path="/organ" element={<OrganList/>}/>
                                <Route path="/gateway" element={<GatewayList/>}/>
                                <Route path="/extension" element={<Extension/>}/>
                                <Route path="/cdr" element={<Cdr/>}/>
                                <Route path="/phone" element={<PhoneList/>}/>
                                <Route path="/allot" element={<AllotPhone/>}/>
                                <Route path="/replace" element={<ReplacePhone/>}/>
                                <Route path="/replace_really" element={<ReplaceReallyPhone/>}/>
                                <Route path="/stat" element={<Stat/>}/>
                                <Route path="/me" element={<Me/>}/>
                            </Routes>
                        </div>
                    </Content>
                    <Footer style={{textAlign: 'center'}}>
                        后台管理系统
                    </Footer>
                </Layout>
            </Layout>
        </ConfigProvider>
    )
}

export default BackendLayout;
