import React, {useState, useEffect} from 'react';
import {Column} from '@ant-design/plots';
import AppAxios from "../../utils/request";
import {Button, Space, DatePicker, Tag, Popconfirm, Table, Typography} from "antd";
import dayjs from 'dayjs';
import {SearchOutlined} from "@ant-design/icons";
import CommonPagination from "../../components/Common/Pagination";
import CommonSelect from "../../components/Common/Select";

const {RangePicker} = DatePicker;
const Stat = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchOrganId, setSearchOrganId] = useState("");
    const [startAt, setStartAt] = useState();
    const [endAt, setEndAt] = useState();

    const columns = [
        {
            title: '月份',
            width: 120,
            dataIndex: 'month',
            key: 'month',
        },
        {title: '机构', width: 200, dataIndex: 'organName', key: 'organName'},
        {title: '整月坐席数', width: 200, dataIndex: 'wholeMonth', key: 'wholeMonth'},
        {title: '半月坐席数', width: 200, dataIndex: 'halfMonth', key: 'halfMonth'},
        {title: '不计费坐席数', width: 200, dataIndex: 'freeNum', key: 'freeNum'},

        {
            title: '操作',
            width: 150,
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        danger
                        size="small">查看</Button>
                </Space>
            ),
        },
    ];

    const getDateList = () => {
        AppAxios.get("/stat/extension_use", {
            params: {
                startAt: startAt,
                endAt: endAt,
                organId: searchOrganId,
            }
        }).then(res => {
            setLoading(false);
            if ((res.code === 2000 || res.code === 0)) {
                setData(res.data)
            }

        }).catch((e) => setLoading(false))
    }


    useEffect(() => {
        if (loading) {
            getDateList()
        }
    }, [loading]);


    return (
        <div>

            <h3>使用统计</h3>


            <div style={{
                lineHeight: 3,
                display: "flex",
                justifyContent: "space-between",
            }}>
                <Space>
                    <RangePicker picker="month"
                                 ranges={{
                                     '上月': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                                     '本月': [dayjs().startOf('month'), dayjs().endOf('month')],
                                     '最近三月': [dayjs().subtract(3, 'month').startOf('month').startOf('day'), dayjs().endOf('month').startOf('day')],
                                     '最近半年': [dayjs().subtract(6, 'month').startOf('month').startOf('day'), dayjs().endOf('month').startOf('day')],
                                 }}
                                 placeholder={["开始日期", "结束日期"]}
                                 onChange={(date1, date2) => {
                                     setStartAt(date2[0]);
                                     setEndAt(date2[1]);
                                     setLoading(true);
                                 }}/>

                    <CommonSelect
                        url={"/organ/select"}
                        placeholder="请选择机构"
                        onChange={(value) => {
                            setSearchOrganId(value);
                            setLoading(true);
                        }}/>


                    <Button onClick={() => {
                        setLoading(true);
                    }} type="primary" icon={<SearchOutlined/>}>搜索</Button>

                    <Button
                        type="primary"
                        onClick={() => {
                            let token = window.localStorage.getItem("token");
                            window.location.href = "/backend/stat/extension_use:export?" +
                                "organId=" + searchOrganId +
                                "&startAt=" + startAt +
                                "&endAt=" + endAt +
                                "&token=" + token +
                                "&rand=" + Date.now()
                        }}>
                        导出
                    </Button>
                </Space>

            </div>
            <Table
                bordered
                columns={columns}
                dataSource={data}
                rowKey={columns => columns.id}
                loading={loading}
                pagination={false}
                summary={(pageData) => {
                    let wholeMonth = 0;
                    let halfMonth = 0;
                    let freeNum = 0;

                    pageData.forEach((item) => {
                        wholeMonth += item.wholeMonth;
                        halfMonth += item.halfMonth;
                        freeNum += item.freeNum;
                    });

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={2}>小计</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <Typography type="danger">{wholeMonth}</Typography>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>
                                    <Typography>{halfMonth}</Typography>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3}>
                                    <Typography>{freeNum}</Typography>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4}>

                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                        </>
                    );
                }}
            />
        </div>
    )

};

export default Stat
