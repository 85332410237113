import {
    Button,
    Form,
    Input,
    InputNumber,
    Modal,
    notification,
    Popconfirm, Radio, Select,
    Space, Spin,
    Table, Tag
} from 'antd';
import React, {useState, useEffect} from 'react';
import AppAxios from "../../utils/request";
import CommonPagination from "../../components/Common/Pagination";
import CommonSelect from "../../components/Common/Select";
import {SearchOutlined} from "@ant-design/icons";


const Extension = () => {
    const [loading, setLoading] = useState(true);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [current, setCurrent] = useState(1);

    const [tableData, setTableData] = useState([]);

    const [addModalVisible, setAddModalVisible] = useState(false);
    const [formAdd] = Form.useForm();

    const [batchAddModalVisible, setBatchAddModalVisible] = useState(false);
    const [formBatchAdd] = Form.useForm();

    const [searchOrganId, setSearchOrganId] = useState("");
    const [searchPhoneId, setSearchPhoneId] = useState("");
    const [searchState, setSearchState] = useState(null);
    const [searchNumber, setSearchNumber] = useState("");
    const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
    const [searchPhoneAttribution, setSearchPhoneAttribution] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`);
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const columns = [
        {
            title: '机构',
            width: 90,
            dataIndex: 'organName',
            key: 'organName',
            render: (text) => <a>{text}</a>,
        },
        {
            title: '分机号',
            width: 66,
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '密码',
            width: 100,
            dataIndex: 'password',
            key: 'password',
        },
        {
            title: '外显号码',
            width: 180,
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            render: (_, record) => (
                <div>
                    <span>{record.phoneNumber}{record.phoneAttribution ? '(' + record.phoneAttribution + ')' : ''}</span>
                </div>
            ),
        },
        {
            title: '状态',
            width: 60,
            dataIndex: 'state',
            key: 'state',
            render: (state) => {
                switch (state) {
                    case 1:
                        return (<Tag color="green">启用</Tag>)
                    case 0:
                        return (<Tag color="red">停用</Tag>)
                    default:
                        return (<Tag>未知</Tag>)
                }
            },
        },
        {
            title: '创建时间',
            width: 180,
            dataIndex: 'createdDate',
            key: 'createdDate',
        },
        {
            title: '操作',
            width: 150,
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            console.log(record)
                            formAdd.setFieldsValue(record)
                            setAddModalVisible(true)
                        }}>编辑</Button>


                    <Popconfirm
                        title="确定要删除吗"
                        onConfirm={() => {
                            AppAxios.delete("/extension/" + record.id).then(res => {
                                if ((res.code === 2000 || res.code === 0)) {
                                    getDateList()
                                }
                            }).catch((e) => {

                            })
                        }}
                        okText="删除"
                        cancelText="取消"
                    >
                        <Button
                            type="primary"
                            danger
                            size="small">删除</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const getDateList = () => {
        AppAxios.get("/extensions", {
            params: {
                page: current,
                pageSize: pageSize,
                organId: searchOrganId,
                phoneId: searchPhoneId,
                number: searchNumber,
                state: searchState,
                phoneNumber: searchPhoneNumber,
                phoneAttribution: searchPhoneAttribution,
            }
        }).then(res => {
            setLoading(false);
            if ((res.code === 2000 || res.code === 0)) {
                setTableData(res.data.list)
                setTotal(res.data.page.total)
            }
        }).catch((e) => setLoading(false))
    }


    useEffect(() => {
        if (loading) {
            getDateList()
        }
    }, [loading, current, pageSize]);


    return (
        <div>
            <h3>分机管理</h3>
            <Spin spinning={loadingDelete}>

            <Button
                type="primary"
                onClick={() => setBatchAddModalVisible(true)}>
                批量新增
            </Button>,

            <Button
                type="primary"
                onClick={() => setAddModalVisible(true)}>
                新增
            </Button>,

            <Popconfirm
                title="确定要删除吗"
                onConfirm={() => {
                    setLoadingDelete(true)
                    AppAxios.post("/extension/delete", selectedRowKeys).then(res => {
                        if ((res.code === 2000 || res.code === 0)) {
                            notification["success"]({
                                message: "操作成功",
                                description: "操作成功",
                            });
                            getDateList();
                        }
                        setLoadingDelete(false)
                    }).catch((e) => {
                        setLoadingDelete(false)
                    })
                }}
                okText="删除"
                cancelText="取消"
            >
                <Button
                    type="primary"
                    danger>批量删除</Button>
            </Popconfirm>

            <div style={{
                lineHeight: 3,
                display: "flex",
                justifyContent: "space-between",
            }}>
                <Space>
                    <CommonSelect
                        url={"/organ/select"}
                        placeholder="请选择机构"
                        onChange={(value) => {
                            setSearchOrganId(value);
                            setCurrent(1);
                            setLoading(true);
                        }}/>
                    <CommonSelect
                        style={{width: 240}}
                        url={"/phones/select"}
                        placeholder="请选择号码"
                        onChange={(value) => {
                            setSearchPhoneId(value);
                            setCurrent(1);
                            setLoading(true);
                        }}/>

                    <Select
                        allowClear
                        placeholder="状态"
                        style={{minWidth: 60}}
                        onChange={(value) => {
                            setSearchState(value);
                        }}>
                        <Select.Option key="1" value={1}>启用</Select.Option>
                        <Select.Option key="2" value={0}>停用</Select.Option>
                    </Select>

                    <Input allowClear placeholder="分机号" style={{width: '120px'}} onChange={(e) => {
                        setSearchNumber(e.target.value)
                    }}/>
                    <Input allowClear placeholder="外显号" style={{width: '120px'}} onChange={(e) => {
                        setSearchPhoneNumber(e.target.value)
                    }}/>
                    <Input allowClear placeholder="归属地" style={{width: '120px'}} onChange={(e) => {
                        setSearchPhoneAttribution(e.target.value)
                    }}/>


                    <Button onClick={() => {
                        setCurrent(1);
                        setLoading(true);
                    }} type="primary" icon={<SearchOutlined/>}>搜索</Button>

                    <Button
                        type="primary"
                        onClick={() => {
                            let token = window.localStorage.getItem("token");
                            window.location.href = "/backend/extensions:export?pageSize=5000" +
                                "&organId=" + searchOrganId +
                                "&phoneId=" + searchPhoneId +
                                "&token=" + token +
                                "&rand=" + Date.now()
                        }}>
                        导出
                    </Button>
                </Space>

            </div>


            <Table
                bordered
                scroll={{x: 1000}}
                columns={columns}
                dataSource={tableData}
                rowKey={columns => columns.id}
                loading={loading}
                pagination={false}
                rowSelection={rowSelection}
                footer={() => (
                    <CommonPagination
                        onChange={(page) => {
                            setCurrent(page);
                            setLoading(true);
                        }}
                        onShowSizeChange={(current, size) => {
                            setCurrent(1);
                            setPageSize(size);
                            setLoading(true);
                        }}
                        current={current}
                        total={total}
                        size={pageSize}
                    />
                )}
            />


            <Modal title="批量创建分机" visible={batchAddModalVisible}
                   onOk={() => {
                       formBatchAdd.validateFields()
                           .then(values => {
                               AppAxios.post("/extension/batch", values).then(res => {
                                   if ((res.code === 2000 || res.code === 0)) {
                                       formBatchAdd.resetFields();
                                       notification["success"]({
                                           message: "操作成功",
                                           description: "操作成功",
                                       });
                                       setBatchAddModalVisible(false);
                                       getDateList();
                                   }
                               }).catch((e) => {

                               })
                           })
                           .catch(info => {
                               console.log('Validate Failed:', info);
                           });
                   }}
                   onCancel={() => {
                       setBatchAddModalVisible(false)
                   }}>
                <div>
                    <Form
                        form={formBatchAdd}
                        name="basic"
                        labelCol={{span: 4,}}
                        initialValues={{remember: true}}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="机构"
                            name="organId"
                            rules={[{required: true, message: '请选择归属机构'}]}
                        >
                            <CommonSelect url={"/organ/select"}/>
                        </Form.Item>


                        <Form.Item
                            label="指定开始"
                            name="beginNumber"
                            min={10000}
                            max={100000}
                        >
                            <InputNumber placeholder="默认从机构最后一个分机号开始" style={{width: '100%'}}/>
                        </Form.Item>

                        <Form.Item
                            label="新增数量"
                            name="numberNum"
                            min={1}
                            max={300}
                            rules={[{required: true, message: '请输入要新增的数量'}]}
                        >
                            <InputNumber style={{width: '100%'}}/>
                        </Form.Item>


                        <Form.Item label="外显号码" name="phoneId">
                            <CommonSelect url={"/phones/select"}/>
                        </Form.Item>

                        <Form.Item label="启用Asr" name="asr">
                            <Radio.Group>
                                <Radio value={1}>开启</Radio>
                                <Radio value={0}>关闭</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>

            <Modal title="创建分机" visible={addModalVisible}
                   onOk={() => {
                       formAdd.validateFields()
                           .then(values => {
                               if (values.id) {
                                   AppAxios.put("/extension", values).then(res => {
                                       if ((res.code === 2000 || res.code === 0)) {
                                           formBatchAdd.resetFields();
                                           notification["success"]({
                                               message: "操作成功",
                                               description: "操作成功",
                                           });
                                           setAddModalVisible(false);
                                           formAdd.resetFields();
                                           getDateList();
                                       }
                                   }).catch((e) => {

                                   })
                               } else {
                                   AppAxios.post("/extension", values).then(res => {
                                       if ((res.code === 2000 || res.code === 0)) {
                                           formBatchAdd.resetFields();
                                           notification["success"]({
                                               message: "操作成功",
                                               description: "操作成功",
                                           });
                                           setAddModalVisible(false);
                                           formAdd.resetFields();
                                           getDateList();
                                       }
                                   }).catch((e) => {

                                   })
                               }

                           })
                           .catch(info => {
                               console.log('Validate Failed:', info);
                           });
                   }}
                   onCancel={() => {
                       setAddModalVisible(false);
                       formAdd.resetFields();
                   }}>
                <div>
                    <Form
                        form={formAdd}
                        name="basic"
                        labelCol={{span: 4,}}
                        initialValues={{remember: true}}
                        autoComplete="off"
                    >
                        <Form.Item label="id" name="id" hidden={true}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="机构"
                            name="organId"
                            rules={[{required: true, message: '请选择归属机构'}]}
                        >
                            <CommonSelect url={"/organ/select"}/>
                        </Form.Item>

                        <Form.Item
                            label="分机号"
                            name="number"
                            rules={[{required: true, message: '请输入分机号的开始值'}]}
                        >
                            <Input style={{width: '100%'}}/>
                        </Form.Item>

                        <Form.Item
                            label="密码"
                            name="password"
                        >
                            <Input style={{width: '100%'}}/>
                        </Form.Item>


                        <Form.Item label="号码" name="phoneId">
                            <CommonSelect url={"/phones/select"}/>
                        </Form.Item>

                        <Form.Item label="启用Asr" name="asr">
                            <Radio.Group>
                                <Radio value={1}>开启</Radio>
                                <Radio value={0}>关闭</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item label="状态" name="state">
                            <Radio.Group>
                                <Radio value={1}>启用</Radio>
                                <Radio value={0}>禁用</Radio>
                            </Radio.Group>
                        </Form.Item>


                        <Form.Item
                            label="姓名"
                            name="name">
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        <Form.Item
                            label="联系电话"
                            name="contactNumber">
                            <Input style={{width: '100%'}}/>
                        </Form.Item>

                        <Form.Item
                            label="备注"
                            name="remark"
                        >
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                        <Form.Item
                            label="axb"
                            name="axb">
                            <Input style={{width: '100%'}}/>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>

            </Spin>
        </div>
    );
}

export default Extension
